'use client'

import { useCallback, useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'

import { SignalClient } from '../../lib/signals/SignalClient'
import { useHeap } from '../_components/chrome/scripts/HeapScript'
import { NostoPlacementId, useNostoClient } from '../_components/chrome/scripts/NostoScript'

import { useAuthentication } from './AuthenticationProvider.client'
import { useLocalization } from './LocalizationProvider.client'

const useTrackingImpl = () => {
  const [signalClient, _setSignalClient] = useState(() => {
    return typeof window !== 'undefined' ? new SignalClient() : undefined
  })
  return {
    signalClient,
  }
}

const TrackingContainer = createContainer(useTrackingImpl)
TrackingContainer.Provider.displayName = 'TrackingProviderImpl'
export const TrackingProvider = TrackingContainer.Provider

type UseTrackFeatureFlagDecisionInput = {
  key: string
  decision: string
}

export const useTrackFeatureFlagDecision = () => {
  const { sendHeapEvent } = useHeap()

  const trackFeatureFlagDecision = useCallback(
    (featureFlagDecision: UseTrackFeatureFlagDecisionInput) => {
      sendHeapEvent({
        eventName: `launch-darkly-feature-flag-${featureFlagDecision.key}`,
        props: {
          [`launch-darkly-feature-flag-${featureFlagDecision.key}-decision`]:
            featureFlagDecision.decision,
        },
      })
    },
    [sendHeapEvent]
  )

  return {
    trackFeatureFlagDecision,
  }
}

type UseTrackProductInteractionInput = {
  handle: string
  id: string
  shopifyId: string
  soldOut?: boolean
}

export const useTrackProductInteraction = () => {
  const { account } = useAuthentication()
  const { signalClient } = TrackingContainer.useContainer()

  const trackProductInteraction = useCallback(
    (productSignal: UseTrackProductInteractionInput) => {
      signalClient?.track({
        event: 'product_interaction',
        attributes: productSignal,
        user: account
          ? {
              emailAddress: account.email,
            }
          : undefined,
      })
    },
    [signalClient, account]
  )

  return {
    trackProductInteraction,
  }
}

type UseTrackProductImpressionInput = {
  featuredProduct: boolean
  handle: string
  id: string
  shopifyId: string
  soldOut: boolean
  variantInformation: Array<{
    size: string
    soldOut: boolean
    discounted: boolean
  }>
}

export const useTrackProductImpression = () => {
  const { account } = useAuthentication()
  const { signalClient } = TrackingContainer.useContainer()

  const trackProductImpression = useCallback(
    (productImpression: UseTrackProductImpressionInput) => {
      signalClient?.track({
        event: 'product_impression',
        attributes: productImpression,
        user: account
          ? {
              emailAddress: account.email,
            }
          : undefined,
      })
    },
    [signalClient, account]
  )

  return {
    trackProductImpression,
  }
}

type UseTrackProductGroupImpressionInput = {
  handle: string
}

export const useTrackProductGroupImpression = () => {
  const { account } = useAuthentication()
  const { signalClient } = TrackingContainer.useContainer()

  const trackProductGroupImpression = useCallback(
    (productGroupImpression: UseTrackProductGroupImpressionInput) => {
      signalClient?.track({
        event: 'product_group_impression',
        attributes: productGroupImpression,
        user: account
          ? {
              emailAddress: account.email,
            }
          : undefined,
      })
    },
    [signalClient, account]
  )

  return {
    trackProductGroupImpression,
  }
}

type PageViewCategory =
  | 'account'
  | 'home'
  | 'landing'
  | 'collection'
  | 'kit'
  | 'product'
  | 'search'
  | 'not found'
  | 'other'

const signalApiPageViewCategories: Record<PageViewCategory, string> = {
  'account': 'other',
  'collection': 'collection',
  'home': 'home',
  'kit': 'collection',
  'landing': 'landing',
  'not found': 'not found',
  'other': 'other',
  'product': 'product',
  'search': 'search',
} as const

const globalValidNostoPlacements = [
  'cartpage-nosto-1',
  'cartpage-nosto-2',
  'cartpage-nosto-3',
  'cartpage-nosto-4',
] as const

const nostoPlacementsForPageCategories: Record<PageViewCategory, NostoPlacementId[]> = {
  'account': [...globalValidNostoPlacements],
  'collection': [...globalValidNostoPlacements],
  'home': [
    'frontpage-nosto-1',
    'frontpage-nosto-2',
    'frontpage-nosto-3',
    'frontpage-nosto-4',
    'frontpage-nosto-5',
    'frontpage-nosto-6',
    'frontpage-nosto-7',
    'frontpage-nosto-8',
    'frontpage-nosto-9',
    'frontpage-nosto-10',
    'frontpage-nosto-11',
    'frontpage-nosto-12',
    ...globalValidNostoPlacements,
  ],
  'kit': [
    'productpage-nosto-4',
    'productpage-nosto-8',
    'productpage-nosto-9',
    'productpage-nosto-10',
    ...globalValidNostoPlacements,
  ],
  'landing': [
    'frontpage-nosto-1',
    'frontpage-nosto-2',
    'frontpage-nosto-3',
    'frontpage-nosto-4',
    'frontpage-nosto-5',
    'frontpage-nosto-6',
    'frontpage-nosto-7',
    'frontpage-nosto-8',
    'frontpage-nosto-9',
    'frontpage-nosto-10',
    'frontpage-nosto-11',
    'frontpage-nosto-12',
    ...globalValidNostoPlacements,
  ],
  'not found': ['notfound-nosto-1', 'notfound-nosto-2', ...globalValidNostoPlacements],
  'other': [...globalValidNostoPlacements],
  'product': ['productpage-nosto-1', ...globalValidNostoPlacements],
  'search': ['searchpage-nosto-1', 'searchpage-nosto-2', ...globalValidNostoPlacements],
} as const

type PageView =
  | {
      pageViewHandle: string
      pageViewCategory: Exclude<PageViewCategory, 'product' | 'search'>
      productShopifyId?: never
      searchQuery?: never
    }
  | {
      pageViewHandle: string
      pageViewCategory: Extract<PageViewCategory, 'product'>
      productShopifyId: string
      searchQuery?: never
    }
  | {
      pageViewHandle: string
      pageViewCategory: Extract<PageViewCategory, 'search'>
      productShopifyId?: never
      searchQuery: string
    }

export const useTrackPageView = ({
  pageViewHandle,
  pageViewCategory,
  productShopifyId,
  searchQuery,
}: PageView) => {
  const { account } = useAuthentication()
  const nostoClient = useNostoClient()
  const { signalClient } = TrackingContainer.useContainer()

  useEffect(() => {
    const nostoPlacementIds = nostoPlacementsForPageCategories[pageViewCategory]
    if (pageViewCategory === 'home') {
      nostoClient.trackHomePageView(nostoPlacementIds)
    } else if (pageViewCategory === 'collection') {
      nostoClient.trackCollectionPageView({
        collectionHandle: pageViewHandle,
        nostoPlacementIds,
      })
    } else if (pageViewCategory === 'kit') {
      nostoClient.trackCollectionPageView({
        collectionHandle: pageViewHandle,
        nostoPlacementIds,
      })
    } else if (pageViewCategory === 'not found') {
      nostoClient.trackNotFoundPageView(nostoPlacementIds)
    } else if (pageViewCategory === 'account') {
      nostoClient.trackGeneralPageView(nostoPlacementIds)
    } else if (pageViewCategory === 'product') {
      nostoClient.trackProductPageView({
        shopifyProductGid: productShopifyId,
        nostoPlacementIds,
      })
    } else if (pageViewCategory === 'search') {
      nostoClient.clearRecommendations(nostoPlacementIds)
      nostoClient.trackSearchEvent(searchQuery, nostoPlacementIds)
    } else if (pageViewCategory === 'landing') {
      nostoClient.trackGeneralPageView(nostoPlacementIds)
    } else {
      nostoClient.trackGeneralPageView()
    }
  }, [nostoClient, pageViewCategory, pageViewHandle, productShopifyId, searchQuery])

  useEffect(() => {
    signalClient?.track({
      event: 'page_view',
      attributes: {
        pageHandle: pageViewHandle,
        pageCategory: signalApiPageViewCategories[pageViewCategory],
      },
      user: account
        ? {
            emailAddress: account.email,
          }
        : undefined,
    })
  }, [pageViewCategory, pageViewHandle, signalClient, account])
}

export const TrackPageView = (props: PageView) => {
  useTrackPageView(props)
  return null
}

type UseTrackCartModifiedInput = {
  id: string
  items: Array<{
    title: string
    effectivePrice: number
    quantity: number
    productShopifyId: string
    variantShopifyId: string
    variantSku: string
  }>
}

export const useTrackCartModified = () => {
  const nostoClient = useNostoClient()
  const { region } = useLocalization()
  const { account } = useAuthentication()
  const { signalClient } = TrackingContainer.useContainer()

  const trackCartModified = useCallback(
    (cartModification: UseTrackCartModifiedInput) => {
      const cartHashKey = '@figs:SignalCartHash'
      const existingCartHashValue = localStorage?.getItem(cartHashKey)
      const initalizingEmptyCart =
        (existingCartHashValue === null && cartModification.items.length === 0) ||
        (!existingCartHashValue?.startsWith(cartModification.id) &&
          cartModification.items.length === 0)
      const cartHashValue =
        cartModification.id +
        '||' +
        cartModification.items.map(item => item.variantSku + '|' + item.quantity).join('||')

      if (!initalizingEmptyCart && cartHashValue !== existingCartHashValue) {
        localStorage?.setItem(cartHashKey, cartHashValue)

        signalClient?.track({
          event: 'cart_modified',
          attributes: cartModification,
          user: account
            ? {
                emailAddress: account.email,
              }
            : undefined,
        })

        const cartChangeProps: Parameters<typeof nostoClient.trackCartChange>[0] = {
          currencyCode: region.currency,
          cartItems: (Object.values(cartModification.items) ?? []).map(item => {
            return {
              title: item.title,
              quantity: item.quantity,
              effectivePriceInCents: item.effectivePrice,
              shopifyProductGid: item.productShopifyId,
              shopifyVariantGid: item.variantShopifyId,
            }
          }),
          nostoPlacementIds: [],
        }
        nostoClient.trackCartChange(cartChangeProps)
      }
    },
    [signalClient, account, region.currency, nostoClient]
  )

  return {
    trackCartModified,
  }
}

type UseTrackSearchResultInput = {
  id: string
  query: string
  searchType: string
  topResults: Array<{
    handle: string
  }>
  totalResults: number
}

export const useTrackSearchResults = () => {
  const { account } = useAuthentication()
  const { signalClient } = TrackingContainer.useContainer()

  const trackSearchResults = useCallback(
    (searchResults: UseTrackSearchResultInput) => {
      if (searchResults.totalResults > 10) {
        searchResults.topResults = searchResults.topResults.slice(0, 10)
      }

      signalClient?.track({
        event: 'search',
        attributes: {
          ...searchResults,
          searchProvider: 'Algolia',
        },
        user: account
          ? {
              emailAddress: account.email,
            }
          : undefined,
      })
    },
    [signalClient, account]
  )

  return {
    trackSearchResults,
  }
}
